import { page, add, checkRecord, analysLink, addToStore, getOrderDetail, parseKsUrl } from "@/api/checkGoods";

const actions = {
    parseKsUrl({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            parseKsUrl(form, state.token)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getOrderDetail({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getOrderDetail(form, state.token)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    addToStore({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            addToStore(form, state.token)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    analysLink({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            analysLink(form, state.token)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    page({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            page(form, state.token)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    add({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            add(form, state.token)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    checkRecord({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            checkRecord(form, state.token)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};

export default {
    actions,
    namespaced: true,
};

import request from "@/utils/request";

export function page(data) {
    return request({
        url: "checkGoods/page",
        method: "post",
        data,
    });
}
export function add(data) {
    return request({
        url: "checkGoods/add",
        method: "post",
        data,
    });
}
export function checkRecord(data) {
    return request({
        url: "checkGoods/checkRecord",
        method: "post",
        data,
    });
}

export function analysLink(data) {
    return request({
        url: "checkGoods/analysLink",
        method: "post",
        data,
    });
}
export function addToStore(data) {
    return request({
        url: "checkGoods/addToStore",
        method: "post",
        data,
    });
}

export function getOrderDetail(data) {
    return request({
        url: "checkGoods/getDetail",
        method: "get",
        params: data,
    });
}
export function parseKsUrl(data) {
    return request({
        url: "checkGoods/parseKsUrl",
        method: "post",
        data,
    });
}   
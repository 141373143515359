import request from "@/utils/request";

// 登录
export function login(data) {
    return request({
        url: '/account/login',
        method: 'post',
        data
    })
}

export function bindRole(data) {
    return request({
        url: '/usercenter/bindRole',
        method: 'post',
        data
    })
}


// 退出登录
export function logout(data) {
    return request({
        url: '/usercenter/logout',
        method: 'post',
        data,
    })
}


// 注册
export function register(data) {
    return request({
        url: '/account/register',
        method: 'post',
        data
    })
}

// 获取用户信息
export function getUserInfo(data) {
    return request({
        url: '/account/getUserInfo',
        method: 'get',
        params: data,
    })
}
// 获取验证码
export function sendVerifyCode(data) {
    return request({
        url: '/account/sendVerifyCode',
        method: 'post',
        data
    })
}

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const constantRoutes = [
    {
        path: "/",
        redirect: { path: '/store', query: { imageType: 0 } },
    },
    {
        path: "/index",
        component: () => import("@/views/index"),
        meta: {
            title: "嗨图云 | 图库管理系统",
        },
        children: [

            {
                path: "/checkrecord",
                name: "checkrecord",
                component: () => import("@/views/checkrecord"),
                meta: {
                    title: "嗨图云 | 图库管理系统",
                },
            },
            {
                path: "/store",
                name: "store",
                component: () => import("@/views/store"),
                meta: {
                    title: "嗨图云 | 图库管理系统",
                },
            },
            {
                path: "/collect",
                name: "collect",
                component: () => import("@/views/collect"),
                meta: {
                    title: "嗨图云 | 我的收藏",
                },
            },
            {
                path: "/permission",
                name: "permission",
                component: () => import("@/views/permission"),
                meta: {
                    title: "嗨图云 | 授权管理",
                },
            },
            {
                path: "/menuedit",
                name: "menuedit",
                component: () => import("@/views/menuedit"),
                meta: {
                    title: "嗨图云 | 自定义标签管理",
                },
            },
            {
                path: "/goods/:id",
                name: "Goods",
                component: () => import("@/views/goodsdetails"),
                meta: {
                    title: "嗨图云 | 商品详情",
                },
            },
            {
                path: "/toShop",
                name: "toShop",
                component: () => import("@/views/toShop"),
                meta: {
                    title: "嗨图云 | 一键发布",
                },
            },
            {
                path: "/juese",
                name: "juese",
                component: () => import("@/views/juese"),
                meta: {
                    title: "图库管理系统",
                },
            },
            {
                path: "/authorize",
                name: "authorize",
                component: () => import("@/views/authorize"),
                meta: {
                    title: "嗨图云 | 商品发布",
                },
                children: [
                    {
                        path: "/fabu",
                        name: "fabu",
                        component: () => import("@/views/authorize/fabu"),
                        meta: {
                            title: "嗨图云 | 批量铺货",
                        },
                    }, {
                        path: "/fabuRecord",
                        name: "fabuRecord",
                        component: () => import("@/views/authorize/fabuRecord"),
                        meta: {
                            title: "嗨图云 | 批量记录",
                        },
                    }, {
                        path: "/updateProduct",
                        name: "updateProduct",
                        component: () => import("@/views/authorize/updateProduct"),
                        meta: {
                            title: "嗨图云 | 批量修改",
                        },
                    }, {
                        path: "/updateRecord",
                        name: "updateRecord",
                        component: () => import("@/views/authorize/updateRecord"),
                        meta: {
                            title: "嗨图云 | 修改记录",
                        },
                    }, {
                        path: "/productList",
                        name: "productList",
                        component: () => import("@/views/authorize/productList"),
                        meta: {
                            title: "嗨图云 | 商品管理",
                        },
                    },
                    {
                        path: "/preProductList",
                        name: "preProductList",
                        component: () => import("@/views/authorize/preProductList"),
                        meta: {
                            title: "嗨图云 | 草稿箱",
                        },
                    },
                ]
            },
            {
                path: "/productDetail",
                name: "productDetail",
                component: () => import("@/views/authorize/productDetail"),
                meta: {
                    title: "嗨图云 | 商品详情",
                },
            },
            {
                path: "/preProductEdit",
                name: "preProductEdit",
                component: () => import("@/views/authorize/preProductEdit"),
                meta: {
                    title: "嗨图云 | 草稿编辑",
                },
            },
            {
                path: "/productEdit",
                name: "productEdit",
                component: () => import("@/views/authorize/productEdit"),
                meta: {
                    title: "嗨图云 | 商品编辑",
                },
            },

        ],
    },
    {
        path: "/factory",
        name: "factory",
        component: () => import("@/views/factory"),
        meta: {
            title: "图库管理系统",
        },
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/login"),
        meta: {
            title: "登录",
        },
    },
    {
        path: "/register",
        name: "register",
        component: () => import("@/views/login/register"),
        meta: {
            title: "注册",
        },
    },
    {
        path: "/404",
        component: () => import("@/views/404.vue"),
    },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
    // mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
});
router.beforeEach((to, from, next) => {
    if(!to.matched.length) {
        next("/404");
        return;
    }
    /* 路由发生变化修改页面title */
    if(to.meta.title) {
        document.title = to.meta.title;
    }
    //1.如果用户访问/login 或者/register 的请求,应该直接放行
    // if(to.path === '/store') return next()

    //2.不是访问的登录页面,所以判断用户是否登录. 判断依据token
    // let token = localStorage.getItem("token")

    //3.如果!token  没有值,则执行if之后的操作
    // if(!token)  return next("/home")

    //4.如果代码执行到这一行,说明用户已经登录.则放行
    next();
});

export default router;
